import { Integrations } from '@/services/FreightApi/Integrations'
import { currentConfigApi } from '@/services/FreightApi/FreightApi'
import { trimObject } from '@/utils/functions'

export default {
  async getApiIntegrations ({ state, commit }) {
    commit('SET_LOADING', true)
    let integrations = new Integrations()

    const filters = trimObject({
      ...currentConfigApi(state),
      ...state.filters
    })

    integrations = await integrations.list(filters)

    if (integrations) {
      commit('SET_INTEGRATIONS', integrations?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', integrations)
    }
    commit('SET_LOADING', false)
  }
}

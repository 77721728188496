import getService from '@/utils/get-service'

export const NODE_ENV = process.env.NODE_ENV

export const FREIGHT_API_BASE_URL = getService().VUE_APP_FREIGHT_API_BASE

export const FREIGHT_API_QUOTATION_BASE_URL = getService().VUE_APP_API_QUOTATION

export const FREIGHT_API_QUOTATION_SIMULATION_BASE_URL = getService().VUE_APP_API_QUOTATION_SIMULATION

export const FREIGHT_API_ORDERS_ANALYSIS_BASE_URL = getService().VUE_APP_API_ORDERS_ANALYSIS

export const FREIGHT_API_NOTIFICATION_URL = getService().VUE_APP_FREIGHT_API_NOTIFICATION_URL

export const FREIGHT_API_KEY = window.parent.IFC_VARIABLES.headers['api-key']
export const FREIGHT_API_API_TOKEN = window.parent.IFC_VARIABLES.auth.token
export const FREIGHT_API_TENANT_ID = window.parent.IFC_VARIABLES.headers['x-tenant-id']

export const SOCKET_BASE_URL = getService().VUE_APP_SOCKET_BASE_URL
export const SOCKET_PATH = process.env.VUE_APP_SOCKET_PATH

export const MFE_URL = process.env.VUE_APP_MFE_URL

export const APPSHELL_URL = process.env.VUE_APP_APPSHELL_URL

export const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE
export const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE

export function scheduledIsValid (config) {
  return config.week.length > 0 && config.startTime && config.endTime
}

export function deliveryModeScheduledIsValid (deliveryModeScheduled) {
  if (deliveryModeScheduled) {
    let isValid = true
    deliveryModeScheduled.forEach(scheduled => {
      if (!scheduledIsValid(scheduled)) {
        isValid = false
      }
    })
    return isValid
  }
  return false
}

export default {
  SET_QUOTATIONS (state, quotations) {
    state.quotations = quotations
  },
  SET_CURRENT_QUOTATION (state, currentQuotation) {
    state.currentQuotation = currentQuotation
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  TOUGGLE_SHOW_FILTERS (state) {
    state.showFilters = !state.showFilters
  },
  ADD_FILTER (state, { field, value }) {
    if (value) {
      const filters = {
        ...state.filters
      }
      filters[field] = value
      state.filters = filters
    }
  },
  SET_FILTERS (state, filters) {
    state.filters = filters
  },
  REMOVE_FILTER (state, field) {
    const filters = {
      ...state.filters
    }
    delete filters[field]
    state.filters = filters
  },
  SET_CURRENT_CONFIG (state, currentConfig) {
    state.currentConfig = currentConfig
  },
  SET_CURRENT_CONFIG_FROM_API (state, { currentPage, total, pages }) {
    state.currentConfig.page = currentPage
    state.total = total
    state.pageCount = pages
  },
  SET_PAGE (state, page) {
    state.currentConfig.page = page
  },
  SET_PER_PAGE (state, perPage) {
    state.currentConfig.perPage = perPage
  },
  SET_SORT_BY (state, sortBy) {
    state.currentConfig.sortBy = sortBy
  },
  SET_SORT_DESC (state, sortDesc) {
    state.currentConfig.sortDesc = sortDesc
  }
}

import { FreightApi } from './FreightApi'

export class Accounts extends FreightApi {
  constructor () {
    super()
    this.uri = '/accounts/locations'
  }

  listAccounts (params = {}) {
    return this.instance.get('/accounts?accountType=account', { params })
      .then(response => (response?.data))
      .catch(() => {
        return false
      })
  }
}

import { FreightApi } from '@/services/FreightApi/FreightApi'

export class ShippingMargins extends FreightApi {
  constructor () {
    super()
    this.uri = '/shipping-margins'
  }

  serviceErrorsDictionary = {
    margin: {
      'already.exist': 'Já existe margem para esta combinação'
    },
    account: {
      'already.exist': 'Já existe margem para esta combinação'
    }
  }

  findAccounts (params = {}) {
    return this.instance.get(`${this.uri}/accounts`, { params })
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(() => {
        return false
      })
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.uri}/${id}`, { params })
      .then(response => {
        const data = response.data.data
        return {
          active: Boolean(data.active),
          margin: parseFloat(data.margin),
          carrier: {
            id: data.carrier.id,
            carrier: data.carrier.name
          },
          deliveryMode: {
            id: data.deliveryMode.id,
            delivery_mode: data.deliveryMode.name
          },
          store: data.accounts.map(store => ({
            id: store.id,
            name: store.name
          })),
          account: data.accounts.map(store => ({
            id: store.id,
            name: store.name
          }))
        }
      }).catch(() => {
        return false
      })
  }

  create (data) {
    return this.instance.post(this.uri, this.formatData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id = null, data = {}, params = {}) {
    const uri = id ? `${this.uri}/${id}` : this.uri
    return this.instance.put(uri, this.formatData(data), { params })
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  formatData (data) {
    return {
      active: true,
      margin: parseFloat(data.margin),
      carrier: {
        id: data.carrier.id,
        name: data.carrier.carrier
      },
      deliveryMode: {
        id: data.deliveryMode.id,
        name: data.deliveryMode.delivery_mode
      },
      accounts: data?.account.map(account => ({
        id: account.id,
        name: account.name
      }))
    }
  }
}

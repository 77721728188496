import { ContractEditor } from '@/views/Contract/ContractEditor'

const contract = 'contract'

export const PATH_CONTRACT = contract

export const NAME_CONTRACT = contract

export default [
  {
    path: PATH_CONTRACT,
    name: NAME_CONTRACT + '-create',
    component: () => import('@/components/IfcEditor/IfcEditorPage.vue'),
    meta: {
      title: 'Nova Tabela de Frete',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Nova Tabela de Frete' }],
      returnButton: true,
      back: {
        route: { name: 'contracts' }
      },
      service: ContractEditor
    }
  },
  {
    path: PATH_CONTRACT + '/:id',
    name: NAME_CONTRACT + '-update',
    component: () => import('@/components/IfcEditor/IfcEditorPage.vue'),
    meta: {
      title: 'Editar Tabela de Frete',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Editar Tabela de Frete' }],
      returnButton: true,
      back: {
        route: { name: 'contracts' }
      },
      service: ContractEditor
    }
  }
]

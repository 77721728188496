import { OrderAnalysisOrders } from '@/services/FreightOrderAnalysisApi/OrderAnalysisOrders'
import { trimObject } from '@/utils/functions'

export default {
  async getApiOrders ({ state, getters, commit }) {
    commit('SET_LOADING', true)

    const filters = trimObject(JSON.parse(JSON.stringify(state.filters)))

    if (filters.status && filters.status.length > 0) {
      filters.status = filters.status.join(',')
    } else {
      delete filters.status
    }

    let orders = new OrderAnalysisOrders()
    orders = await orders.list({
      ...getters.currentConfigApi,
      ...filters
    })
    if (orders) {
      commit('SET_ORDERS', orders?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', orders)
    }
    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', {})
      dispatch('getApiOrders')
    }
  },
  async exportData ({ commit }, period) {
    commit('SET_EXPORT_LOADING', true)
    let ordersExport = new OrderAnalysisOrders()
    ordersExport = await ordersExport.export({
      ...period
    })
    commit('SET_EXPORT_LOADING', false)
    return ordersExport
  }
}

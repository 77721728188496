<template>
  <ifc-counter
    v-model="model"
    type="number"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :optional="optional"
    :rules="rulesFormated"
    :hide-hint="true"
    :mask="mask"
    :max="max"
    :min="min"
    :disabled="disabled || loading"
    :loading="loading"
    :error-messages="errors"
    @keypress="resetError()"
  ></ifc-counter>
</template>
<script>
export default {
  name: 'IfcEditorCounter',
  computed: {
    model: {
      get () {
        return this.value ? this.value.toString() : ''
      },
      set (value) {
        this.$emit('input', parseFloat(value))
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    },
    resetError () {
      if (this.errors?.length > 0) {
        this.$emit('resetError', this.field)
      }
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    mask: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    }
  }
}
</script>

import { IfcEditor } from '@/components/IfcEditor/IfcEditor'
import { CNPJValidation } from '@/utils/CNPJValidation'
import { ShippingCompanies } from '@/services/FreightApi/ShippingCompanies'

import Validator from '@infralabs/infra-components-vue/dist/lib/validator'

export class ShippingCompaniesEditor extends IfcEditor {
  constructor (id = null) {
    super(id)
  }

  title = 'Transportadora'

  fields = {
    carrier: {
      type: 'input',
      label: 'Nome da transportadora',
      placeholder: 'Nome da transportadora',
      hint: 'Até 50 caracteres',
      rules: [
        v => (!v || (v && v.length <= 50)) || 'Até 50 caracteres'
      ],
      required: true
    },
    email: {
      type: 'input',
      label: 'Email',
      optional: true,
      placeholder: 'E-mail da transportadora',
      rules: [
        ...new Validator().isEmail().rules()
      ]
    },
    document: {
      type: 'input',
      label: 'CNPJ',
      placeholder: '00.000.000/0000-00',
      mask: '##.###.###/####-##',
      required: true,
      rules: [
        v => CNPJValidation(v) || 'CNPJ inválido'
      ]
    },
    phone: {
      type: 'phone',
      label: 'Telefone',
      optional: true,
      placeholder: '+xx (xx) x xxxx-xxxx'
      // mask: ({ form }) => {
      //   const phoneNumber = form?.phone
      //   const clearPhone = (phoneNumber || ' ').replace(/\D/g, '')

      //   if (clearPhone.length < 13) {
      //     return '+## (##) ####-####'
      //   }

      //   return '+## (##) # ####-####'
      // }
    },
    observation: {
      type: 'input',
      label: 'Observação',
      optional: true,
      placeholder: 'Observação',
      hint: 'Até 50 caracteres',
      rules: [
        v => (!v || (v && v.length <= 50)) || 'Até 50 caracteres'
      ]
    },
    active: {
      type: 'status',
      label: 'Status',
      defaultValue: true
    }
  }

  service = new ShippingCompanies()

  onSaving (form) {
    form.document = form.document.replace(/[^0-9]/gi, '')
  }
}

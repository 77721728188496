import { Notification } from '@/services/FreightApi/Notification'

export default {
  async getApiNotifications ({ getters, commit }) {
    const notifications = new Notification()
    const response = await notifications.list(getters.notificationsParams)
    commit('SET_TOTAL_ITEMS', response?.total)
    commit('SET_UNREAD_COUNT', response?.unreadCount || 0)
    commit('SET_NEXT_PAGE', response)
    return response?.data || []
  },
  async getNotifications ({ commit, dispatch }) {
    commit('SET_LOADING', true)
    commit('RESET_NEXT_PAGE', 1)
    commit('SET_NOTIFICATIONS', await dispatch('getApiNotifications') || [])
    commit('SET_LOADING', false)
  },
  async paginateNotifications ({ state, commit, dispatch }) {
    if (state.nextPage) {
      commit('SET_LOADING', true)
      commit('PUSH_NOTIFICATIONS', await dispatch('getApiNotifications') || [])
      commit('SET_LOADING', false)
    }
  },
  async putApiNotifications (scope, params) {
    const notifications = new Notification()
    return await notifications.update(null, {}, params)
  },
  async readAllNotifications ({ commit, dispatch }) {
    commit('SET_LOADING', true)
    await dispatch('putApiNotifications', {
      all: true
    })
    dispatch('getNotifications')
    commit('SET_LOADING', false)
  },
  async readNotification ({ commit, dispatch }, { notificationId, read }) {
    return await dispatch('putApiNotifications', {
      notificationId,
      read
    })
  }
}

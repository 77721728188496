<template>
  <div>
    <component
      v-model="model"
      :is="getComponent()"
      v-bind="{ ...$props }"
    ></component>
  </div>
</template>
<script>
export default {
  name: 'IfcEditorComponent',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    },
    resetError () {
      if (this.errors?.length > 0) {
        this.$emit('resetError', this.field)
      }
    },
    getComponent () {
      return this.component
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    mask: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    },
    component: {
      default: null
    },
    mode: {
      default: null
    },
    save: {
      default: null
    },
    form: {
      default: null
    }
  }
}
</script>

import Vue from 'vue'
import store from '@/store'
import IfcEditor from '@/components/IfcEditor/IfcEditor'
import editors from './editors'

const ifcEditor = IfcEditor.start(store, editors)

Vue.prototype.$IfcEditor = ifcEditor.create

export default {}

const quotations = 'quotations'

export const PATH_QUOTATIONS = quotations

export const NAME_QUOTATIONS = quotations

export default {
  path: PATH_QUOTATIONS,
  name: NAME_QUOTATIONS,
  component: () => import('@/views/Quotations'),
  meta: {
    breadcrumb: [{ text: 'Cotação' }, { text: 'Auditoria de cotações' }],
    title: 'Auditoria de cotações'
  }
}

import { Axios } from '@/plugins/axios/Axios'
// import auth from '@/utils/app-shell-auth'
import errorsDictionary from '../FreightApi/errorsDictionary'
import {
  NODE_ENV,
  FREIGHT_API_TENANT_ID,
  FREIGHT_API_QUOTATION_BASE_URL
} from '@/constants/enviroments'

export class FreightQuotationApi extends Axios {
  #uri;

  constructor () {
    const uriBase = ''
    super({ baseURL: FREIGHT_API_QUOTATION_BASE_URL + uriBase })
    this.#requestInterceptor()
    this.#responseInterceptor()
  }

  get uri () {
    return this.#uri
  }

  set uri (uri) {
    this.#uri = uri
  }

  #requestInterceptor () {
    this.instance.interceptors.request.use(async config => {
      if (NODE_ENV === 'development') {
        config.headers['X-Tenant-Id'] = FREIGHT_API_TENANT_ID
      } else {
        config.headers['X-Tenant-Id'] = window.parent.IFC_VARIABLES.headers['x-tenant-id']
      }
      return config
    },
    error => {
      return Promise.reject(error)
    })
  }

  #responseInterceptor () {
    this.instance.interceptors.response.use(response => {
      return response
    },
    async error => {
      const statusCode = error?.response?.status
      this.#responseStatusCodeActions(statusCode)
      return Promise.reject(error)
    })
  }

  #responseStatusCodeActions (statusCode) {
    switch (statusCode) {
      case 401:
        break
      case 403:
        break
      case 404:
        break
    }
  }

  list (params = {}) {
    return this.instance.get(this.#uri, { params })
      .then(response => (response?.data))
      .catch(() => {
        return false
      })
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.#uri}/${id}`, { params })
      .then(response => {
        const orderData = response?.data?.data
        orderData.history = (orderData?.historyOrderByASC || []).reverse()

        return orderData
      })
      .catch(() => {
        return false
      })
  }

  create (data) {
    return this.instance.post(this.#uri, data)
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id = null, data = {}, params = {}) {
    const uri = id ? `${this.#uri}/${id}` : this.#uri
    return this.instance.put(uri, data, { params })
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  delete (id = null, params = {}) {
    const uri = id ? `${this.#uri}/${id}` : this.#uri
    return this.instance.delete(uri, { params })
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  checkResponseError (error) {
    if (error?.response?.status) {
      const response = error.response
      switch (response.status) {
        case 400:
          return this.errorsParse(response?.data?.errors || {})
      }
    }
    return false
  }

  errorsParse (errors) {
    const errorsFormated = {}
    if (errors instanceof Object) {
      for (const key in errors) {
        errorsFormated[key] = this.errorParse(errors[key])
      }
    }
    return errorsFormated
  }

  errorParse (fieldErrors) {
    if (fieldErrors && Array.isArray(fieldErrors)) {
      return fieldErrors?.map(error => (errorsDictionary[error]))
    }
    return []
  }
}

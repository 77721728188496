export const LOCALSTORAGE_CACHE_STORE = 'b0889ebd-de9a-4307-915f-2fec5e9d0f33'

export const LOCALSTORAGE_CACHE_SHIPPING_COMPANIES_TABLE_CONFIGURATION = 'LOCALSTORAGE_CACHE_SHIPPING_COMPANIES_TABLE_CONFIGURATION'
export const LOCALSTORAGE_CACHE_SHIPPING_COMPANIES_FILTERS = 'LOCALSTORAGE_CACHE_SHIPPING_COMPANIES_FILTERS'
export const LOCALSTORAGE_CACHE_INTEGRATIONS_TABLE_CONFIGURATION = 'LOCALSTORAGE_CACHE_INTEGRATIONS_TABLE_CONFIGURATION'
export const LOCALSTORAGE_CACHE_INTEGRATIONS_FILTERS = 'LOCALSTORAGE_CACHE_INTEGRATIONS_FILTERS'

export const LOCALSTORAGE_CACHE_ORDERS_FILTERS = 'LOCALSTORAGE_CACHE_ORDERS_FILTERS'
export const LOCALSTORAGE_CACHE_ORDERS_TABLE_CONFIGURATION = 'LOCALSTORAGE_CACHE_ORDERS_TABLE_CONFIGURATION'

export const LOCALSTORAGE_CACHE_CONTRACTS_TABLE_CONFIGURATION = '2d4d8eeb-7285-4d02-abae-a0944b0c8d85'
export const LOCALSTORAGE_CACHE_CONTRACTS_FILTERS = '24e5cb5a-a110-4914-adb1-8b100ec88185'

export const LOCALSTORAGE_CACHE_DASHBOARD_MONITORING_DATE_FILTER = '0c8ea428-775f-4be7-afa3-2bb2e010eb38'

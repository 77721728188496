<template>
  <div>
    <v-dialog v-model="dialog" persistent width="350">
      <v-card>
        <v-app-bar dense flat color="white">
          <v-spacer></v-spacer>
          <v-btn icon color="black" @click="close()">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-title class="ifc-editor-dialog__title">
          <v-spacer></v-spacer>
          <ifc-typography :text="title" typography-style="h3" text-weight="700" />
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="ifc-editor-dialog__content">
          <IfcEditor
            :id="id"
            :config="config"
            v-model="ifcEditor"
            @onSaving="onSaving"
            @onSaved="onSaved"
            @onSuccess="onSuccess"
            @onError="onError"
          ></IfcEditor>
        </v-card-text>
        <v-card-actions class="ifc-editor-dialog__actions">
          <ifc-button
            block
            large
            color="primary"
            v-if="ifcEditor.isValid"
            @click="ifcEditor.save()"
            v-text="buttonActionText"
            :disabled="!ifcEditor.isValid()"
            :loading="ifcEditor.loading"
          ></ifc-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import IfcEditor from './IfcEditor.vue'
export default {
  name: 'IfcEditorDialog',
  data: () => ({
    ifcEditor: {},
    dialog: true,
    config: {}
  }),
  computed: {
    id () {
      return this.instance.id
    },
    title () {
      const preTitle = this.id ? 'Editar ' : 'Adicionar '
      return preTitle + this.config.title
    },
    buttonActionText () {
      return this.id ? 'Atualizar' : 'Salvar'
    }
  },
  methods: {
    close: function () {
      this.$store.commit('IfcEditor/REMOVE_INSTANCE', this.instance.index)
    },
    setConfig: function () {
      const Editor = this.instance.config
      this.config = new Editor(this.id)
    },
    onSaving: function (form) {
      if (this.instance.onSaving) {
        this.instance.onSaving(form)
      }
    },
    onSaved: function (form) {
      if (this.instance.onSaved) {
        this.instance.onSaved(form)
      }
    },
    onSuccess: function (form) {
      if (this.instance.onSuccess) {
        this.instance.onSuccess(form)
      }
      this.close()
    },
    onError: function (form) {
      if (this.instance.onError) {
        this.instance.onError(form)
      }
    }
  },
  created: function () {
    this.setConfig()
  },
  props: {
    instance: {
      required: true
    }
  },
  components: {
    IfcEditor
  }
}
</script>
<style scoped>
  .ifc-editor-dialog__title {
    font-size: 24px !important;
    padding: 0 !important;
  }
  .ifc-editor-dialog__content {
    padding-top: 35px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .ifc-editor-dialog__actions {
    padding: 0px 35px 35px 35px !important;
  }
</style>

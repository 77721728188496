import { QuotationSimulation } from '@/services/FreightQuotationApi/QuotationSimulation'

export default {
  async simulateQuotation ({ state, getters, commit }) {
    commit('SET_LOADING', true)
    let quotation = new QuotationSimulation()
    quotation = await quotation.simulate({
      ...getters.simulation
    })
    if (quotation?.data) {
      commit('SET_SIMULATION_CACHE', getters.simulation)
      commit('SET_SIMULATION', [])
      commit('SET_QUOTATION', JSON.parse(JSON.stringify(quotation?.data)) || [])
    }
    commit('SET_LOADING', false)

    return quotation
  },
  clearSimulation ({ getters, commit }) {
    if (getters.simulation) {
      commit('SET_SIMULATION', [])
    }
  }
}

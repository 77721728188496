const quotation = 'quotation'

export const PATH_QUOTATION = quotation

export const NAME_QUOTATION = quotation

export default [
  {
    path: PATH_QUOTATION + '/simulator',
    name: NAME_QUOTATION + '-simulator',
    component: () => import('@/views/Quotation/Simulator/Simulator'),
    meta: {
      breadcrumb: [{ text: 'Cotacão' }, { text: 'Simulador' }],
      title: 'Simulador de Cotação',
      returnButton: false
    }
  },
  {
    path: PATH_QUOTATION + '/result/:entity/:id',
    name: NAME_QUOTATION + '-result',
    component: () => import('@/views/Quotation/Simulator/Result'),
    meta: {
      breadcrumb: [{ text: 'Cotacão' }, { text: 'Resultado' }],
      title: 'Detalhe de Cotação',
      returnButton: true,
      back: {
        route: { name: 'quotations' }
      }
    }
  }
]

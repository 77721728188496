import App from '@/App.vue'
import plugins from '@/plugins'
import router, { createRouter } from '@/router'
import store from '@/store'
import auth from '@/utils/app-shell-auth'
import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    $auth: () => auth
  }
})

const postMessageTemp = window.parent.postMessage
window.parent.postMessage = function (message, targetOrigin, transfer) {
  postMessageTemp(JSON.parse(JSON.stringify(message)), targetOrigin, transfer)
}

Vue.filter('currency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  return formatter.format(value)
})

const options = { router, store, ...plugins }

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    ...options,
    render (h) {
      return h(App, {
        props: {
          basepath: this.basepath,
          location: this.location
        }
      })
    },
    el: '#mfe_div_connector'
  },
  handleInstance (app) {
    auth.listen(app.tokenIdSubject, app.$root.$emit, app.$root)
  }
})

export const bootstrap = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.bootstrap(props)
  })
}

export const mount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.mount(props)
  })
}

export const unmount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.unmount(props)
  })
}

export const withoutSingleSpa = ({ elementDOM, basePath, location, auth }) => {
  return new Vue({
    mixins: [
      {
        router: createRouter(basePath),
        mounted: function () {
          if (location) {
            router.push(location)
          }
        },
        ...options,
        computed: {
          $auth: () => auth
        }
      }
    ],
    render (h) {
      return h(App, {})
    }
  }).$mount(elementDOM)
}

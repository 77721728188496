<template>
  <v-dialog v-model="dialog" v-if="active" width="278">
    <v-card>
      <v-app-bar flat dense color="white">
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text class="message">
        <div class="message__icon">
          <img :src="$FileProxy(config.icon)" :width="config.iconWidth" alt=""/>
        </div>
        <div class="message__text-title" v-html="config.title"></div>
        <div class="message__text-subtitle" v-html="config.subtitle"></div>
        <v-btn
          block
          large
          depressed
          v-html="'OK'"
          class="white--text"
          :color="config.color"
          @click="dialog = false"
        ></v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Message',
  computed: {
    ...mapState('IfcEditor/Message', ['active', 'type']),
    dialog: {
      get () {
        return this.active
      },
      set (value) {
        this.$store.commit('IfcEditor/Message/SET_ACTIVE', value)
      }
    },
    config () {
      const mode = {
        proccess: {
          icon: 'static/icons/proccess-icon.PNG',
          iconWidth: '100',
          title: 'Solicitação em processamento',
          subtitle: 'Você será notificado ao término',
          color: '#2F3C4E'
        },
        success: {
          icon: 'static/icons/success-icon.png',
          iconWidth: '70',
          title: null,
          subtitle: 'Os dados foram salvos com sucesso',
          color: 'success'
        },
        error: {
          icon: 'static/icons/error-icon.png',
          iconWidth: '70',
          title: null,
          subtitle: 'Algo deu errado, tente mais tarde ou contate o suporte',
          color: '#7676FF'
        }
      }
      return mode[this.type]
    }
  }
}
</script>
<style scoped>
  .message {
    padding: 0 18px 39px 18px !important;
  }
  .message__icon {
    text-align: center;
  }
  .message__text {
    text-align: center;
    font-size: 16px;
    padding: 30px 0;
  }
  .message__text-title {
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    font-weight: bold;
  }
  .message__text-subtitle {
    text-align: center;
    font-size: 16px;
    padding-bottom: 30px;
  }
</style>

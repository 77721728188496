import prepareHomepage from '@/router/guard/prepareHomepage'
import LoggedLayout from '@/layout/Logged'
import SHIPPING_COMPANIES from '@/router/routes/shippingCompanies'
import CONTRACTS, { NAME_CONTRACTS } from '@/router/routes/contracts'
import CONTRACT from '@/router/routes/contract'
import ACCOUNTS_DELIVERY_MODES from '@/router/routes/accountsDeliveryModes'
import DELIVERY_MODES from '@/router/routes/deliveryModes'
import SHIPPING_MARGINS from '@/router/routes/shippingMargins'
import INTEGRATIONS from '@/router/routes/integrations'
import QUOTATIONS from '@/router/routes/quotations'
import DASHBOARD_MONITORING from '@/router/routes/dashboardMonitoring'
import QUOTATION from '@/router/routes/quotation'
import DASHBOARD_MONITORING_DETAIL from '@/router/routes/dashboardMonitoringDetail'

export const PATH = '/'

export default [
  {
    path: PATH,
    component: LoggedLayout,
    children: [
      prepareHomepage(SHIPPING_COMPANIES),
      prepareHomepage(CONTRACTS),
      ...prepareHomepage(CONTRACT),
      prepareHomepage(ACCOUNTS_DELIVERY_MODES),
      prepareHomepage(DELIVERY_MODES),
      prepareHomepage(SHIPPING_MARGINS),
      prepareHomepage(INTEGRATIONS),
      prepareHomepage(QUOTATIONS),
      prepareHomepage(DASHBOARD_MONITORING),
      ...prepareHomepage(QUOTATION),
      prepareHomepage(DASHBOARD_MONITORING_DETAIL)
    ],
    redirect: {
      name: NAME_CONTRACTS
    }
  }
]

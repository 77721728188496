import { IfcEditor } from '@/components/IfcEditor/IfcEditor'
import { DeliveryMode } from '@/services/FreightApi/DeliveryMode'

export class DeliveryModeEditor extends IfcEditor {
  constructor (id = null) {
    super(id)
  }

  title = 'modalidade'

  rules = [
    ({ form }) => (form?.delivery_mode?.length > 0)
  ]

  fields = {
    delivery_mode: {
      type: 'input',
      label: 'Nome da modalidade',
      required: true,
      hint: 'Até 50 caracteres',
      rules: [
        v => (!v || (v && v.length <= 50)) || 'Até 50 caracteres'
      ]
    },
    active: {
      type: 'status',
      label: 'Status',
      defaultValue: true
    }
  }

  service = new DeliveryMode()
}

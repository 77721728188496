import { IfcEditor } from '@/components/IfcEditor/IfcEditor'
import { ShippingMargins } from '@/services/FreightApi/ShippingMargins'
import { DeliveryMode } from '@/services/FreightApi/DeliveryMode'
import { Locations } from '@/services/FreightApi/Locations'
import { ShippingCompanies } from '@/services/FreightApi/ShippingCompanies'

export class ShippingMarginsEditor extends IfcEditor {
  constructor (id = null) {
    super(id)
  }

  title = 'Margem'

  rules = [
    ({ form }) => (form?.account?.length > 0)
  ]

  fields = {
    carrier: {
      type: 'combobox-api',
      label: 'Transportadora',
      placeholder: 'Selecione',
      service: new ShippingCompanies(),
      serviceParams: {
        active: true
      },
      keyLabel: 'carrier',
      keyValue: 'id',
      required: true
    },
    deliveryMode: {
      type: 'combobox-api',
      label: 'Modalidade',
      placeholder: 'Selecione',
      service: new DeliveryMode(),
      serviceParams: {
        active: true
      },
      keyLabel: 'delivery_mode',
      keyValue: 'id',
      required: true
    },
    account: {
      type: 'combobox-api',
      label: 'Lojas',
      placeholder: 'Selecione',
      service: new Locations(),
      serviceParams: {
        active: true,
        accountType: 'account'
      },
      keyLabel: 'name',
      keyValue: 'id',
      required: true,
      multiple: true
    },
    margin: {
      type: 'number',
      label: 'Margem',
      placeholder: '000%',
      required: true,
      max: 100,
      min: -1,
      rules: [
        v => (parseFloat(v) > -1) || 'Por favor, insira um valor igual ou maior que 0%',
        v => (parseFloat(v) < 101) || 'Maior que 100%'
      ]
    }
  }

  service = new ShippingMargins()
}

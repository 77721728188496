import { ShippingMargins } from '@/services/FreightApi/ShippingMargins'
import getters from './getters'
import { trimObject } from '@/utils/functions'

export default {
  async getApiShippingMargins ({ state, commit }) {
    commit('SET_LOADING', true)
    let shippingMargins = new ShippingMargins()

    const filters = trimObject({
      ...getters.currentConfigApi(state),
      ...state.filters
    })

    shippingMargins = await shippingMargins.list(filters)

    if (shippingMargins) {
      commit('SET_SHIPPING_COMPANIES', shippingMargins?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', shippingMargins)
    }
    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', [])
      dispatch('getApiShippingMargins')
    }
  }
}

import state from './state'
import mutations from './mutations'
import Message from './Message'
import Page from './Page'

export default {
  namespaced: true,
  state,
  mutations,
  modules: {
    Message,
    Page
  }
}

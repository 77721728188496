export default {
  SET_FORM (state, form) {
    state.form = form
  },
  SET_VALID (state, valid) {
    state.valid = valid
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

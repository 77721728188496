import ifcEdtorStore from './store'

export class IfcEditor {
  service;
  fields;
  title;
  id;

  constructor (id = null) {
    this.id = id
  }
}

const start = (store, editors) => {
  store.registerModule('IfcEditor', ifcEdtorStore)
  const create = ({ editor, id, onSaving, onSaved, data }) => {
    store.commit('IfcEditor/ADD_INSTANCE', {
      id,
      data,
      onSaved,
      onSaving,
      config: editors[editor]
    })
  }
  return { create }
}

export default { start }

import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/Auth'
import Orders from '@/store/Orders'
import Contracts from '@/store/Contracts'
import Integrations from '@/store/Integrations'
import Quotations from '@/store/Quotations'
import QuotationSimulation from '@/store/QuotationSimulation'
import Notifications from '@/store/Notifications'
import DeliveryModes from '@/store/DeliveryModes'
import ShippingMargins from '@/store/ShippingMargins'
import ShippingCompanies from '@/store/ShippingCompanies'
import AccountsDeliveryModes from '@/store/AccountsDeliveryModes'
import OrderAnalysisDashboard from '@/store/OrderAnalysisDashboard'
import FiltersStorage from '@/store/FiltersStorage'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ShippingCompanies,
    ShippingMargins,
    Notifications,
    Contracts,
    AccountsDeliveryModes,
    OrderAnalysisDashboard,
    DeliveryModes,
    Integrations,
    Quotations,
    QuotationSimulation,
    Auth,
    Orders,
    FiltersStorage
  }
})

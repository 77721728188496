import { IfcEditor } from '@/components/IfcEditor/IfcEditor'
import { Accounts } from '@/services/FreightApi/Accounts'
import { Contract } from '@/services/FreightApi/Contract'
import { DeliveryMode } from '@/services/FreightApi/DeliveryMode'
import { Locations } from '@/services/FreightApi/Locations'
import { SalesChannels } from '@/services/FreightApi/SalesChannels'
import { ShippingCompanies } from '@/services/FreightApi/ShippingCompanies'
import { ShippingMargins } from '@/services/FreightApi/ShippingMargins'
import { UUID } from '@/utils/UUID'
import { deliveryModeScheduledIsValid } from './DeliveryModeScheduled/deliveryModeScheduled'

export class ContractEditor extends IfcEditor {
  constructor (id = null) {
    super(id)

    this.lastCarrierId = ''
    this.lastDeliveryModeId = ''
    this.marginLoading = false
    this.accounts = []
    this.marginService = new ShippingMargins()
  }

  title = {
    create: 'Nova Tabela de Frete',
    update: 'Editar Tabela de Frete'
  }

  rules = [
    this.hasFilesRule,
    this.hasLocationsRule,
    this.deliveryModeScheduledRule,
    this.hasMarginAvailable
  ]

  fields = {
    name: {
      type: 'input',
      label: 'Nome da tabela',
      placeholder: 'Nome da tabela',
      hint: 'Até 50 caracteres',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      rules: [(v) => !v || (v && v.length <= 50) || 'Até 50 caracteres'],
      required: true,
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0)
    },
    carrier: {
      type: 'combobox-api',
      label: 'Transportadora',
      placeholder: 'Selecione',
      service: new ShippingCompanies(),
      serviceParams: {
        active: true
      },
      keyLabel: 'carrier',
      keyValue: 'id',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      required: true,
      change: ({ form }) => {
        this.handleMarginRule(form)
      },
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0)
    },
    deliveryMode: {
      type: 'combobox-api',
      label: 'Modalidade',
      placeholder: 'Selecione',
      service: new DeliveryMode(),
      serviceParams: {
        active: true
      },
      keyLabel: 'delivery_mode',
      keyValue: 'id',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      required: true,
      change: ({ form }) => {
        this.handleMarginRule(form)
      },
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0)
    },
    minTime: {
      type: 'counter',
      label: 'Início calendário (Dias)',
      required: true,
      max: 100,
      min: 15,
      defaultValue: 15,
      rules: [
        (v) => parseFloat(v) > 0 || 'Menor que 1',
        (v) => parseFloat(v) > 14 || 'Valor mínimo de 15 dias'
      ],
      cols: { cols: 12, xs: 12, sm: 12, md: 3, lg: 3, xl: 3 },
      show: ({ form }) => form.deliveryMode && form.deliveryMode.scheduled,
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0)
    },
    maxTime: {
      type: 'counter',
      label: 'Tempo máximo (Dias)',
      required: true,
      max: 100,
      min: 0,
      defaultValue: 1,
      rules: [(v) => parseFloat(v) > 0 || 'Menor que 1'],
      cols: { cols: 12, xs: 12, sm: 12, md: 3, lg: 3, xl: 3 },
      show: ({ form }) => form.deliveryMode && form.deliveryMode.scheduled,
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0)
    },
    deliveryScheduleSettings: {
      type: 'component',
      component: () => () => import('@/views/Contract/DeliveryModeScheduled'),
      show: ({ form }) => form.deliveryMode && form.deliveryMode.scheduled,
      defaultValue: [
        {
          id: UUID.v4(),
          week: [],
          startTime: null,
          endTime: null
        }
      ],
      disabled: ({ mode, form }) =>
        mode.update ||
        (form.selectedLocations && form.selectedLocations.length > 0),
      cols: { cols: 12 }
    },
    location: {
      type: 'combobox-api',
      label: 'Local de despacho',
      placeholder: 'Selecione',
      service: new Locations(),
      serviceParams: {
        active: true,
        shipToAddress: true,
        accountType: 'location'
      },
      dependsOn: ['carrier', 'deliveryMode'],
      keyLabel: 'name',
      keyValue: 'id',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode, form }) =>
        !form.name ||
        !form.carrier ||
        !form.deliveryMode ||
        (form.deliveryMode &&
          form.deliveryMode.scheduled &&
          (!form.minTime ||
            !form.maxTime ||
            !deliveryModeScheduledIsValid(form.deliveryScheduleSettings)))
    },
    stores: {
      type: 'combobox-api',
      label: 'Lojas',
      placeholder: 'Selecione',
      service: new Accounts(),
      serviceParams: ({ form }) => ({
        locationId: form?.location?.id || null
      }),
      initialLoad: false,
      dependsOn: ['carrier', 'deliveryMode', 'location'],
      disabled: ({ mode, form }) => !form.location || form.location.length < 1,
      keyLabel: 'name',
      keyValue: 'id',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      multiple: true
    },
    salesChannels: {
      type: 'combobox-api',
      label: 'Canal de Vendas',
      placeholder: 'Selecione',
      service: new SalesChannels(),
      serviceParams: ({ form }) => ({
        accounts: form?.stores?.map((s) => s.id).join(',') || null
      }),
      initialLoad: false,
      dependsOn: ['carrier', 'deliveryMode', 'location', 'stores'],
      disabled: ({ mode, form }) =>
        !form.location || !form.stores || form.stores.length < 1,
      keyLabel: 'name',
      keyValue: 'id',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      multiple: true
    },
    weekends: {
      type: 'combobox',
      label: 'Finais de semana e feriados',
      placeholder: 'Selecione',
      keyLabel: 'name',
      keyValue: 'code',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      required: false,
      options: [
        {
          code: 'saturday',
          name: 'Sábado'
        },
        {
          code: 'sunday',
          name: 'Domingo'
        },
        {
          code: 'holidays',
          name: 'Feriados'
        }
      ],
      multiple: true,
      disabled: ({ mode }) => mode.update
    },
    addLocation: {
      type: 'button',
      error: ({ form }) => {
        const stores = form?.stores || []
        const storeIsValid = stores.every(({ id }) => {
          return this.accounts.some(({ id: accountId }) => accountId === id)
        })

        if (stores.length && !storeIsValid) {
          return true
        }

        return (
          !this.marginLoading &&
          form.carrier &&
          form.deliveryMode &&
          typeof form.margin !== 'number'
        )
      },
      errorMessage: 'Não existe margem aplicada, favor revisar a regra.',
      label: 'OK',
      disabled: ({ mode, form }) => {
        const val =
          (!this.marginLoading &&
            form.carrier &&
            form.deliveryMode &&
            form.salesChannels &&
            typeof form.margin !== 'number') ||
          !form.location ||
          !form.stores ||
          form.stores.length < 1 ||
          !form.salesChannels ||
          form.salesChannels.length < 1 ||
          (form.deliveryMode &&
            form.deliveryMode.scheduled &&
            !deliveryModeScheduledIsValid(form.deliveryScheduleSettings))
        return val
      },
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      action: ({ form, setForm, resetValidation }) => {
        return () => {
          if (!form.selectedLocations) {
            form.selectedLocations = []
          }
          const key = UUID.v4()
          form.selectedLocations.splice(form.selectedLocations.length, 0, {
            key: key,
            ...form.location,
            accounts: form.stores,
            salesChannels: form.salesChannels
          })
          delete form.location
          delete form.stores
          delete form.salesChannels
          resetValidation()
          setForm(form)
        }
      }
    },
    active: {
      type: 'status',
      label: 'Status',
      defaultValue: false,
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      hint: ({ mode }) => {
        return mode.create ? 'Não é possível ativar na criação' : ''
      },
      disabled: ({ mode }) => mode.create,
      persistentHint: ({ mode }) => mode.create
    },
    minimumValueAcceptable: {
      type: 'currency',
      label: 'Valor mínimo',
      placeholder: 'R$ 0,00',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode }) => mode.update
    },
    maximumValueAcceptable: {
      type: 'currency',
      label: 'Valor máximo',
      placeholder: 'R$ 0,00',
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode }) => mode.update
    },
    minimumPercent: {
      type: 'status',
      label: 'Frete mínimo ou %',
      defaultValue: false,
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode, formClone }) =>
        mode.update && formClone.minimumPercent === true
    },
    // spacer: {
    //   type: 'spacer',
    //   cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }
    // },
    taxCubeFactor: {
      type: 'number',
      label: 'Fator de cubagem',
      placeholder: '0',
      rules: [(v) => !v || (v && v.length <= 15) || 'Até 15 caracteres'],
      defaultValue: 0,
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode }) => mode.update
    },
    exemptionCubeFactor: {
      type: 'number',
      label: 'Isenção de cubagem (Kg)',
      placeholder: '0',
      rules: [(v) => !v || (v && v.length <= 15) || 'Até 15 caracteres'],
      defaultValue: 0,
      cols: { cols: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
      disabled: ({ mode }) => mode.update
    },
    files: {
      type: 'file',
      label: 'Upload da Tabela',
      multiple: true,
      maxSize: 52428800, // 50MB
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      show: this.hasLocationsRule,
      cols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
      disabled: ({ mode, form }) =>
        mode.update ||
        !form.selectedLocations ||
        form.selectedLocations.length < 1
    }
  }

  messages = {
    success: 'proccess',
    error: 'error'
  }

  service = new Contract()

  hasLocationsRule ({ form }) {
    return form?.selectedLocations?.length > 0
  }

  hasFilesRule ({ form }) {
    return form?.files?.length > 0
  }

  deliveryModeScheduledRule ({ form }) {
    return (
      !form.deliveryMode ||
      !form.deliveryMode.scheduled ||
      deliveryModeScheduledIsValid(form.deliveryScheduleSettings)
    )
  }

  hasMarginAvailable ({ form }) {
    return typeof form.margin === 'number'
  }

  handleMarginRule (form) {
    if (this.marginLoading || !form.carrier || !form.deliveryMode) {
      return false
    }

    if (
      this.lastCarrierId === form.carrier.id &&
      this.lastDeliveryModeId === form.deliveryMode.id
    ) {
      return false
    }

    this.lastCarrierId = form.carrier.id
    this.lastDeliveryModeId = form.deliveryMode.id
    this.marginLoading = true

    this.marginService
      .findAccounts({
        carrierId: form.carrier.id,
        deliveryModeId: form.deliveryMode.id
      })
      .then(({ data }) => {
        this.accounts = data?.map(({ accounts }) => accounts).flat() || []
        form.margin = data[0]?.margin
        this.marginLoading = false
      })
  }
}

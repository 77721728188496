import { UUID } from '@/utils/UUID'

export default {
  ADD_INSTANCE (state, instance) {
    state.instances.push({
      ...instance,
      index: UUID.v4()
    })
  },
  REMOVE_INSTANCE (state, instanceIndex) {
    const index = state.instances.findIndex(instance => (instance.index === instanceIndex))
    if (index > -1) {
      state.instances.splice(index, 1)
    }
  }
}

import { Axios } from '@/plugins/axios/Axios'
// import auth from '@/utils/app-shell-auth'
import errorsDictionary from './errorsDictionary'
import {
  NODE_ENV,
  FREIGHT_API_TENANT_ID,
  FREIGHT_API_QUOTATION_SIMULATION_BASE_URL
} from '@/constants/enviroments'

export class FreightQuotationSimulationApi extends Axios {
  #uri;

  constructor () {
    const uriBase = ''
    super({ baseURL: FREIGHT_API_QUOTATION_SIMULATION_BASE_URL + uriBase })
    this.#requestInterceptor()
    this.#responseInterceptor()
  }

  get uri () {
    return this.#uri
  }

  set uri (uri) {
    this.#uri = uri
  }

  #requestInterceptor () {
    this.instance.interceptors.request.use(async config => {
      if (NODE_ENV === 'development') {
        config.headers['X-Tenant-Id'] = FREIGHT_API_TENANT_ID
      } else {
        config.headers['X-Tenant-Id'] = window.parent.IFC_VARIABLES.headers['x-tenant-id']
      }
      return config
    },
    error => {
      return Promise.reject(error)
    })
  }

  #responseInterceptor () {
    this.instance.interceptors.response.use(response => {
      return response
    },
    async error => {
      const statusCode = error?.response?.status
      this.#responseStatusCodeActions(statusCode)
      return Promise.reject(error)
    })
  }

  #responseStatusCodeActions (statusCode) {
    switch (statusCode) {
      case 401:
        break
      case 403:
        break
      case 404:
        break
    }
  }

  formatCreateData (data) {
    const formattedItems = data.items.map((item) => {
      return {
        quantity: 1,
        ...item,
        skuId: '',
        modal: '',
        unitaryValue: parseFloat(item.unitaryValue),
        volume: {
          dimension: {
            height: parseFloat(item.volume.dimension.height),
            width: parseFloat(item.volume.dimension.width),
            length: parseFloat(item.volume.dimension.length)
          },
          weight: parseFloat(item.volume.weight)
        }
      }
    })

    const formatedData = {
      data: {
        freight: {
          ...data,
          items: formattedItems
        }
      }
    }

    return formatedData
  }

  simulate (data) {
    const headers = {
      headers: {
        'Quotation-Type': 'simulator'
      }
    }
    return this.instance.post(this.#uri, this.formatCreateData(data), headers)
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  checkResponseError (error) {
    if (error?.response?.status) {
      const response = error.response
      switch (response.status) {
        case 400:
          return this.errorsParse(response?.data?.errors || {})
        case 404:
          return this.errorsParse(response?.data?.errors || {})
      }
    }
    return false
  }

  errorsParse (errors) {
    const errorsFormated = []
    if (errors instanceof Object) {
      for (const key in errors) {
        errorsFormated.push(this.errorParse(errors[key]))
      }
    }
    return errorsFormated
  }

  errorParse (fieldError) {
    if (!fieldError || !fieldError?.code) {
      return ''
    }

    return errorsDictionary[fieldError.code]
  }
}

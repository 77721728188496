const integrations = 'integrations'

export const PATH_INTEGRATIONS = integrations

export const NAME_INTEGRATIONS = integrations

export default {
  path: PATH_INTEGRATIONS,
  name: NAME_INTEGRATIONS,
  component: () => import('@/views/Integrations'),
  meta: {
    breadcrumb: [
      { text: 'Integrações' }
    ],
    title: 'Integrações',
    action: false
  }
}

export default {
  SET_QUOTATION (state, quotation) {
    state.quotation = quotation
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_CURRENT_CONFIG (state, currentConfig) {
    state.currentConfig = currentConfig
  },
  SET_SIMULATION (state, simulation) {
    state.simulation = simulation
  },
  SET_SIMULATION_CACHE (state, simulation) {
    state.simulation_cache = simulation
  }
}

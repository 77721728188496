import { FreightApi } from '@/services/FreightApi/FreightApi'

export class ShippingCompanies extends FreightApi {
  constructor () {
    super()
    this.uri = '/carrier'
  }

  serviceErrorsDictionary = {
    carrier: {
      'already.exist': 'Transportadora já existe'
    },
    document: {
      'already.exist': 'CNPJ já existe'
    }
  }
}

export default {
  loading: (state) => {
    return state.loading
  },

  quotation: (state) => {
    return state.quotation
  },

  simulation: (state) => {
    const simulation = { ...state.simulation }
    for (const key in state.simulation) {
      if ((key === 'originZipCode' || key === 'destinationZipCode') && state.simulation[key]) {
        simulation[key] = state.simulation[key].replaceAll('-', '')
      }
    }
    return simulation
  },

  simulation_cache: (state) => {
    const simulation = { ...state.simulation_cache }
    for (const key in state.simulation_cache) {
      if ((key === 'originZipCode' || key === 'destinationZipCode') && state.simulation_cache[key]) {
        simulation[key] = state.simulation_cache[key].replaceAll('-', '')
      }
    }
    return simulation
  }
}

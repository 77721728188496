/* eslint-disable no-new */
import { io } from 'socket.io-client'
import { Notification } from '@/plugins/socket/Notification'
import {
  FREIGHT_API_API_TOKEN,
  FREIGHT_API_TENANT_ID,
  FREIGHT_API_KEY,
  SOCKET_BASE_URL,
  SOCKET_PATH,
  NODE_ENV
} from '@/constants/enviroments'
class Socket {
  #socket;

  async initialize () {
    const authToken = NODE_ENV === 'development' ? false : window.parent.IFC_VARIABLES.auth.token
    if (NODE_ENV === 'development' || authToken) {
      this.#socket = io(SOCKET_BASE_URL, {
        path: SOCKET_PATH,
        transports: ['websocket'],
        query: this.#auth(),
        auth: this.#auth()
      })

      // this.#socket.on('connect_error', () => {})

      this.#channels()
    }
  }

  #auth () {
    const token = NODE_ENV === 'development' ? FREIGHT_API_API_TOKEN : window.parent.IFC_VARIABLES.auth.token

    if (NODE_ENV === 'development') {
      return {
        Authorization: token,
        'X-Tenant-Id': FREIGHT_API_TENANT_ID,
        'api-key': FREIGHT_API_KEY
      }
    }
    return {
      Authorization: token,
      'X-Tenant-Id': window.parent.IFC_VARIABLES.headers['x-tenant-id'],
      'api-key': window.parent.IFC_VARIABLES.headers['api-key']
    }
  }

  #channels () {
    new Notification(this.#socket)
  }

  async disconnect () {
    await this.#socket.disconnect()
  }
}

export default Socket

<!-- eslint-disable space-before-function-paren -->
<template>
  <div>
    <v-app>
      <router-view />
      <IfcEditorDialogs />
    </v-app>
  </div>
</template>

<script>
import IfcEditorDialogs from '@/components/IfcEditor/IfcEditorDialogs'
import router from './router'
export default {
  name: 'App',
  props: ['location'],
  data () {
    return {
      newRoute: ''
    }
  },
  components: {
    IfcEditorDialogs
  },
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  methods: {
    handleRouteChange (event) {
      router.push(event.detail.newRoute)
    }
  },
  created () {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
      `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/freight`,
      ''
      )
      : this.$route.fullPath
    router.push(this.newRoute)
  },
  mounted () {
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
<style>
.v-application {
  background: #fafafc !important;
}

.ifc-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ifc-combobox >>> div.v-input__slot > fieldset {
  margin-top: 5px !important;
}
.ifc-combobox--primary .v-input__control .v-input__slot fieldset {
  margin-top: 5px;
}
</style>

const DEFAULT_CURRENT_CONFIG = {
  page: 1,
  perPage: 10
}

const DEFAULT_VALUE = {
  shippingMargins: [],
  total: 0,
  filters: {},
  pageCount: 0,
  loading: false,
  showFilters: false,
  currentConfig: DEFAULT_CURRENT_CONFIG
}

export default DEFAULT_VALUE

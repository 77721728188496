import { Notification } from '@/components/Notifications/Notification'

const STATUS_FINISHED = 'finished'
const STATUS_PROCESSING = 'processing'

/* eslint-disable no-dupe-class-members */
export class StoreStocksImportFileNotification extends Notification {
  constructor (args) {
    super({ ...args })
    const payload = this.getPayload()
    this.processStatus(payload)
    this.#setComponent()
  }

  #setComponent () {
    this.component = () => import(
      '@/components/Notifications/Types/StoreStocksImportFileNotification.vue'
    )
  }

  processStatus (payload) {
    const fileName = payload?.batches?.find(({ fileName }) => fileName)?.fileName

    if (payload?.errors?.length) {
      const fileNameErrors = payload?.errors.map(({ name, url }) => {
        this.link = url
        return name
      })
      const list = new Intl.ListFormat('pt-br', { style: 'long', type: 'conjunction' }).format(fileNameErrors)
      this.title = 'Importação de estoque ocorreu um erro'
      this.subtitle = `Ocorreu um erro no(s) seguinte(s) arquivo(s) ${list}.`
      return
    }

    this.title = 'Importação de estoque pendente'
    this.subtitle = `A importação do arquivo ${fileName} será realizada em breve.`

    if (payload.status === STATUS_FINISHED) {
      this.title = 'Importação de estoque finalizada'
      this.subtitle = `A importação do arquivo ${fileName} finalizou.`
    }

    if (payload.status === STATUS_PROCESSING) {
      this.title = 'Importação de estoque em andamento'
      this.subtitle = `O arquivo ${payload.fileName} está sendo processado.`
    }
  }
}

const TITLE_BASE = 'Frete'

export default (router) => {
  router.beforeEach(async (to, _, next) => {
    document.title = createPageTitle(to.meta.title)
    return next()
  })
}

const createPageTitle = (title) => {
  if (title) {
    return title + ' - ' + TITLE_BASE
  }
  return TITLE_BASE
}

import { Contracts } from '@/services/FreightApi/Contracts'
import { trimObject } from '@/utils/functions'

export default {
  async getApiContracts ({ state, getters, commit }) {
    commit('SET_LOADING', true)
    let contracts = new Contracts()
    const filters = trimObject(JSON.parse(JSON.stringify(state?.filters)))
    if (filters.account) {
      filters.accountIds = filters.account.join(',')
      delete filters.account
    }
    contracts = await contracts.list({
      ...getters.currentConfigApi,
      ...filters
    })
    if (contracts) {
      commit('SET_CONTRACTS', contracts?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', contracts)
    }
    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', [])
      dispatch('getApiContracts')
    }
  }
}

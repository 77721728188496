<template>
  <div class="ifc-editor">
    <v-form ref="ifcEditor" v-model="valid">
      <v-row>
        <v-col
          v-for="(field, fieldKey) in fields"
          :key="fieldKey"
          v-bind="field.cols || { cols: '12' }"
          class="ifc-editor-dialog__col"
          :class="{'ifc-editor__hide-col': !checkIfShowField(field)}"
        >
          <div v-if="checkIfShowField(field)">
            <div v-if="field.type == 'input'">
              <IfcEditorInput
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                @resetError="resetError"
              ></IfcEditorInput>
            </div>
            <div v-if="field.type == 'phone'">
              <IfcEditorPhone
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                @resetError="resetError"
              ></IfcEditorPhone>
            </div>
            <div v-if="field.type == 'number'">
              <IfcEditorNumber
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                @resetError="resetError"
              ></IfcEditorNumber>
            </div>
            <div v-if="field.type == 'counter'">
              <IfcEditorCounter
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                @resetError="resetError"
              ></IfcEditorCounter>
            </div>
            <div v-if="field.type == 'status'">
              <IfcEditorStatus
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
              ></IfcEditorStatus>
            </div>
            <div v-if="field.type == 'autocomplete'">
              <IfcEditorAutocomplete
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
              ></IfcEditorAutocomplete>
            </div>
            <div v-if="field.type == 'combobox'">
              <IfcEditorCombobox
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                @resetError="resetError"
                @onChange="setIfcEditor"
              ></IfcEditorCombobox>
            </div>
            <div v-if="field.type == 'combobox-api'">
              <IfcEditorComboboxApi
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
                :formChanged="{ ...formChanged }"
                @resetError="resetError"
                @onChange="onChange"
              ></IfcEditorComboboxApi>
            </div>
            <div v-if="field.type == 'button'">
              <IfcEditorButton
                v-bind="{ ...formatField(field, fieldKey) }"
              ></IfcEditorButton>
            </div>
            <div v-if="field.type == 'file'">
              <IfcEditorFile
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...formatField(field, fieldKey) }"
              ></IfcEditorFile>
            </div>
            <div v-if="field.type == 'title'">
              <IfcEditorTitle
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...getIfcEditor(), ...formatField(field, fieldKey) }"
              ></IfcEditorTitle>
            </div>
            <div v-if="field.type == 'currency'">
              <IfcEditorCurrency
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...getIfcEditor(), ...formatField(field, fieldKey) }"
              ></IfcEditorCurrency>
            </div>
            <div v-if="field.type == 'component'">
              <IfcEditorComponent
                v-model="form[field.value || fieldKey]"
                v-bind="{ ...getIfcEditor(), ...formatField(field, fieldKey) }"
              ></IfcEditorComponent>
            </div>
            <v-col v-if="field.type == 'spacer'" v-bind="{ ...formatField(field, fieldKey) }" ></v-col>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import IfcEditorFile from './Fields/IfcEditorFile.vue'
import IfcEditorInput from './Fields/IfcEditorInput.vue'
import IfcEditorPhone from './Fields/IfcEditorPhone.vue'
import IfcEditorTitle from './Fields/IfcEditorTitle.vue'
import IfcEditorNumber from './Fields/IfcEditorNumber.vue'
import IfcEditorCounter from './Fields/IfcEditorCounter.vue'
import IfcEditorStatus from './Fields/IfcEditorStatus.vue'
import IfcEditorButton from './Fields/IfcEditorButton.vue'
import IfcEditorCurrency from './Fields/IfcEditorCurrency.vue'
import IfcEditorCombobox from './Fields/IfcEditorCombobox.vue'
import IfcEditorComponent from './Fields/IfcEditorComponent.vue'
import IfcEditorComboboxApi from './Fields/IfcEditorComboboxApi.vue'
import IfcEditorAutocomplete from './Fields/IfcEditorAutocomplete.vue'
export default {
  name: 'IfcEditor',
  data: () => ({
    formChanged: {},
    loading: false,
    dialog: true,
    valid: false,
    errors: {},
    form: {},
    formClone: {}
  }),
  computed: {
    fields () {
      return this.config.fields
    },
    service () {
      return this.config.service
    },
    buttonActionText () {
      return this.id ? 'Atualizar' : 'Salvar'
    },
    actionCols () {
      if (this.config.cancel) {
        return {
          cols: 12, sm: 12, md: 5, lg: 4, xl: 3
        }
      }
      return {
        cols: 12
      }
    },
    rules () {
      return this?.config?.rules || []
    },
    mode () {
      return {
        name: !this.id ? 'create' : 'update',
        create: !this.id,
        update: !!this.id
      }
    }
  },
  methods: {
    ...mapActions('IfcEditor/Message', ['showMessage']),
    save: async function () {
      this.onSaving()
      this.loading = true
      this.errors = []
      let response = null
      if (this.id) {
        response = await this.update()
      } else {
        response = await this.create()
      }
      this.checkResponse(response)
      this.onSaved()
      this.loading = false
      this.$forceUpdate()
    },
    onSaving () {
      this.$emit('onSaving', this.form)
      if (this.config.onSaving) {
        this.config.onSaving(this.form)
      }
    },
    onSaved () {
      this.$emit('onSaved', this.form)
      if (this.config.onSaved) {
        this.config.onSaved(this.form)
      }
    },
    find: function () {
      if (this.id) {
        this.findById()
      }
    },
    findById: async function () {
      this.loading = true
      const response = await this.service.findById(this.id)
      if (response) {
        this.$nextTick(() => {
          this.form = Object.assign({}, this.form, response)
          this.formClone = Object.assign({}, this.formClone, response)
        })
      } else {
        this.showMessage(this.config?.messages?.error || 'error')
      }
      this.loading = false
    },
    checkResponse: function (response) {
      if (response?.status) {
        this.showMessage(this.config?.messages?.success || 'success')
        this.$emit('onSuccess', this.form)
        this.form = response.data || {}
        this.formClone = response.data || {}
      } else if (response?.error) {
        this.errors = response.error
        this.$emit('onError', {
          errors: this.errors,
          form: this.form
        })
      } else {
        this.showMessage(this.config?.messages?.error || 'error')
      }
    },
    create: async function () {
      return await this.service.create(this.form)
    },
    update: async function () {
      return await this.service.update(this.id, this.form)
    },
    setForm: function (form) {
      this.form = form
      this.setIfcEditor()
      this.$forceUpdate()
    },
    resetValidation: function () {
      const form = this.$refs.ifcEditor
      form.resetValidation()
    },
    formatField: function (field, fieldIndex) {
      const formatedField = {
        loading: this.loading,
        field: field.value || fieldIndex,
        errors: this.errors[field.value || fieldIndex]
      }
      for (const key in field) {
        if (typeof field[key] === 'function') {
          formatedField[key] = field[key]({
            ...this.getIfcEditor()
          })
        } else {
          formatedField[key] = field[key]
        }
      }
      return formatedField
    },
    resetError: function (field) {
      delete this.errors[field]
    },
    getIfcEditor () {
      return {
        mode: this.mode,
        save: this.save,
        form: this.form,
        formClone: JSON.parse(JSON.stringify(this.formClone)),
        valid: this.valid,
        errors: this.errors,
        loading: this.loading,
        setForm: this.setForm,
        isValid: this.isValid,
        resetValidation: this.resetValidation
      }
    },
    setIfcEditor () {
      this.$emit('input', this.getIfcEditor())
      this.$emit('change', this.getIfcEditor())
      this.$forceUpdate()
    },
    onChange: function (field) {
      this.formChanged = {
        field,
        ...this.getIfcEditor()
      }
    },
    checkIfShowField: function (field) {
      if (typeof field.show === 'function') {
        return field.show({
          ...this.getIfcEditor()
        })
      }
      return field.show || true
    },
    isValid: function () {
      let isValid = true
      if (this?.rules?.length > 0) {
        this.rules.map(rule => {
          if (!rule({ ...this.getIfcEditor() })) {
            isValid = false
          }
          return null
        })
      }
      return this.valid && isValid
    }
  },
  mounted: function () {
    this.find()
  },
  props: {
    value: {
      default: {}
    },
    config: {
      required: true
    },
    id: {
      default: null
    }
  },
  watch: {
    form () {
      this.setIfcEditor()
    },
    valid () {
      this.setIfcEditor()
    },
    loading () {
      this.setIfcEditor()
    }
  },
  components: {
    IfcEditorFile,
    IfcEditorInput,
    IfcEditorPhone,
    IfcEditorTitle,
    IfcEditorStatus,
    IfcEditorButton,
    IfcEditorNumber,
    IfcEditorCounter,
    IfcEditorCombobox,
    IfcEditorCurrency,
    IfcEditorComponent,
    IfcEditorComboboxApi,
    IfcEditorAutocomplete
  }
}
</script>
<style scoped>
  .ifc-editor__actions {
    padding: 0px 0px 35px 0px !important;
  }
  .ifc-editor__actions .v-btn {
    text-transform: none;
  }
  .ifc-editor-dialog__col {
    padding: 0 12px 18px 12px !important;
  }
  .ifc-editor__hide-col {
    display: none !important;
  }
</style>

const getUrl = (path) => {
  return window.parent.IFC_VARIABLES.baseURL + path
}

const getService = () => {
  return {
    VUE_APP_FREIGHT_API_BASE: getUrl(process.env.VUE_APP_API_FREIGHT_PATH),
    VUE_APP_API_QUOTATION: getUrl(process.env.VUE_APP_API_QUOTATION_PATH),
    VUE_APP_API_QUOTATION_SIMULATION: getUrl(process.env.VUE_APP_API_QUOTATION_SIMULATION_PATH),
    VUE_APP_API_ORDERS_ANALYSIS: getUrl(process.env.VUE_APP_API_ORDERS_ANALYSIS_PATH),
    VUE_APP_FREIGHT_API_NOTIFICATION_URL: getUrl(process.env.VUE_APP_FREIGHT_API_NOTIFICATION_URL_PATH),
    VUE_APP_SOCKET_BASE_URL: window.parent.IFC_VARIABLES.baseURL
  }
}

export default getService

<template>
  <ifc-combobox
    :hint="hint"
    v-model="model"
    :label="label"
    :return-object="true"
    :loading="loading"
    :options="options"
    :deletableChips="multiple"
    :key-label="keyLabel"
    :key-value="keyValue"
    :required="required"
    :multiple="multiple"
    :optional="optional"
    :rules="rulesFormated"
    :error-messages="errors"
    @change="onChange"
    persistent-placeholder
    @keypress="resetError()"
    :placeholder="placeholder"
    :disabled="disabled || loading"
    class="contracts-filter__status ifc-input ifc-input--small ifc-input--primary"
    style="min-height: 40px !important;"
  ></ifc-combobox>
</template>
<script>
export default {
  name: 'IfcEditorCombobox',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    },
    resetError () {
      if (this.errors?.length > 0) {
        this.$emit('resetError', this.field)
      }
    },
    onChange () {
      const data = {
        field: this.field,
        value: this.model
      }
      if (this.change) {
        this.change(data)
      }
      this.$emit('onChange', data)
      this.$forceUpdate()
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    mask: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ([])
    },
    defaultValue: {
      default: null
    },
    keyLabel: {
      type: String,
      default: ''
    },
    keyValue: {
      type: String,
      default: ''
    },
    multiple: {
      default: false
    },
    change: {
      type: Function,
      default: null
    }
  }
}
</script>
<style scoped>
  div.v-input__slot > fieldset {
    margin-top: 5px !important;
  }
</style>

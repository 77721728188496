import { Notification } from '@/components/Notifications/Notification'
import { APPSHELL_URL } from '@/constants/enviroments'
import { PATH } from '@/router/routes'
import { PATH_CONTRACT } from '@/router/routes/contract'

export class UploadFreightsSuccessNotification extends Notification {
  constructor (args) {
    super({ ...args })
    const payload = this.getPayload()
    this.subtitle = `Tabela ${(payload?.name ? payload.name + ' ' : '' || '').toUpperCase()}enviada com sucesso`
    this.link = APPSHELL_URL + `${PATH}/${PATH_CONTRACT}/${payload.id}`
  }
}

<template>
  <ifc-combobox
    :hint="hint"
    v-model="model"
    :label="label"
    :return-object="true"
    :loading="loadingOptions || loading"
    :options="options"
    :deletableChips="multiple"
    :key-label="keyLabel"
    :key-value="keyValue"
    :required="required"
    :multiple="multiple"
    :optional="optional"
    :rules="rulesFormated"
    :error-messages="errors"
    @change="onChange"
    persistent-placeholder
    @keypress="resetError()"
    :placeholder="placeholder"
    :disabled="disabled || loading"
    class="contracts-filter__status ifc-input ifc-input--small ifc-input--primary"
    style="min-height: 40px !important;"
  ></ifc-combobox>
</template>
<script>
import { Debounce } from '@/utils/Debounce'
export default {
  name: 'IfcEditorComboboxApi',
  data: () => ({
    loadingOptions: false,
    dependsOnData: {},
    debounce: null,
    options: []
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    async getOptionsFromApi () {
      this.loadingOptions = true
      const items = await this.service.list({
        perPage: 10000,
        ...this.serviceParams
      })
      if (items?.data?.length > 0) {
        this.options = items.data
      } else {
        this.options = []
      }
      this.loadingOptions = false
      this.setDefaultValue()
    },
    updateOptionsFromApi () {
      if (!this.disabled) {
        this.loadingOptions = true
        this.debounce = Debounce.execute(this.debounce, () => {
          this.getOptionsFromApi()
        }, 500)
      }
    },
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    },
    resetError () {
      if (this.errors?.length > 0) {
        this.$emit('resetError', this.field)
      }
    },
    onChange () {
      const data = {
        field: this.field,
        value: this.model
      }
      if (this.change) {
        this.change(data)
      }
      this.resetError()
      this.$emit('onChange', data)
      this.$forceUpdate()
    },
    checkIfDependsOnFieldChanged () {
      if (this.dependsOn?.length > 0 && this.dependsOn?.indexOf(this.formChanged?.field?.field) > -1) {
        this.model = null
        this.updateOptionsFromApi()
      }
    },
    initComboBoxApi () {
      if (this.initialLoad) {
        this.getOptionsFromApi()
      }
    }
  },
  mounted: function () {
    this.initComboBoxApi()
  },
  watch: {
    disabled () {
      this.updateOptionsFromApi()
    },
    required () {
      this.$forceUpdate()
    },
    formChanged () {
      this.checkIfDependsOnFieldChanged()
    }
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    mask: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    dependsOn: {
      type: Array,
      default: () => ([])
    },
    initialLoad: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    },
    multiple: {
      default: false
    },
    service: {
      required: true
    },
    serviceParams: {
      type: Object,
      default: () => ({})
    },
    formChanged: {
      type: Object,
      required: true
    },
    keyLabel: {
      type: String,
      default: ''
    },
    keyValue: {
      type: String,
      default: ''
    },
    change: {
      type: Function,
      default: null
    }
  }
}
</script>
<style>
  .ifc-combobox.v-text-field.v-text-field--enclosed {
    margin-top: 0px !important;
  }
</style>

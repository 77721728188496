import '@/plugins/socket/socket'
import i18n from '@/plugins/i18n'
import '@/plugins/FileProxy'
import infraComponents from '@/plugins/infraComponents'
import VMask from '@/plugins/VMask'
import IfcEditor from '@/plugins/IfcEditor'

export default {
  i18n,
  ...infraComponents,
  VMask,
  IfcEditor
}

import store from '@/store'

const deliveryModes = 'delivery-modes'

export const PATH_DELIVERY_MODES = deliveryModes

export const NAME_DELIVERY_MODES = deliveryModes

export default {
  path: PATH_DELIVERY_MODES,
  name: NAME_DELIVERY_MODES,
  component: () => import('@/views/DeliveryModes'),
  meta: {
    breadcrumb: [
      { text: 'Cadastro' },
      { text: 'Cadastro de Modalidades' }
    ],
    title: 'Cadastro de Modalidades',
    action: {
      text: 'Adicionar Modalidade',
      icon: 'add',
      ifcEditor: {
        editor: 'DeliveryModes',
        onSaved: () => store.dispatch('DeliveryModes/getApiDeliveryModes')
      }
    }
  }
}

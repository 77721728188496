<template>
  <div>
    <div v-for="(instance, instanceKey) in instances" :key="instanceKey">
      <IfcEditor
        :instance="instance"
      ></IfcEditor>
    </div>
    <Message/>
  </div>
</template>
<script>

import IfcEditor from './IfcEditorDialog.vue'
import Message from './Message/Message.vue'
export default {
  name: 'IfcEditorDialogs',
  computed: {
    instances () {
      return this.$store.state.IfcEditor.instances
    }
  },
  components: {
    IfcEditor,
    Message
  }
}
</script>

const accountsDeliveryModes = 'accounts-delivery-mode'

export const PATH_ACCOUNTS_DELIVERY_MODES = accountsDeliveryModes

export const NAME_ACCOUNTS_DELIVERY_MODES = accountsDeliveryModes

export default {
  path: PATH_ACCOUNTS_DELIVERY_MODES,
  name: NAME_ACCOUNTS_DELIVERY_MODES,
  component: () => import('@/views/AccountsDeliveryModes'),
  meta: {
    breadcrumb: [{ text: 'Regras' }, { text: 'Modalidade por loja' }],
    title: 'Modalidade por loja'
  }
}

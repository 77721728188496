import { IfcEditor } from '@/components/IfcEditor/IfcEditor'
import { AccountsDeliveryMode } from '@/services/FreightApi/AccountsDeliveryMode'
import { DeliveryMode } from '@/services/FreightApi/DeliveryMode'
import { Locations } from '@/services/FreightApi/Locations'

export class AccountsDeliveryModeEditor extends IfcEditor {
  constructor (id = null) {
    super(id)
  }

  title = 'vínculo'

  rules = [
    ({ form }) => (form?.accounts?.length > 0)
  ]

  fields = {
    deliveryMode: {
      type: 'combobox-api',
      label: 'Modalidade',
      placeholder: 'Selecione',
      service: new DeliveryMode(),
      serviceParams: {
        active: true
      },
      keyLabel: 'delivery_mode',
      keyValue: 'id',
      required: true
    },
    accounts: {
      type: 'combobox-api',
      label: 'Lojas',
      placeholder: 'Selecione',
      service: new Locations(),
      serviceParams: {
        active: true,
        accountType: 'account'
      },
      keyLabel: 'name',
      keyValue: 'id',
      required: true,
      multiple: true
    },
    friendlyName: {
      type: 'input',
      label: 'Nome do Cliente',
      required: true,
      hint: 'Até 50 caracteres',
      rules: [
        v => (!v || (v && v.length <= 50)) || 'Até 50 caracteres'
      ]
    }
  }

  service = new AccountsDeliveryMode()
}

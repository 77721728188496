import { FreightApi } from './FreightApi'

export class DeliveryMode extends FreightApi {
  constructor () {
    super()
    this.uri = '/delivery-mode'
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.uri}/${id}`, { params })
      .then(response => (this.formatFindByIdResponse(response.data.data)))
      .catch(() => {
        return false
      })
  }

  create (data) {
    return this.instance.post(this.uri, this.formatData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id, data) {
    return this.instance.put(`${this.uri}/${id}`, this.formatData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  serviceErrorsDictionary = {
    delivery_mode: {
      'already.exist': 'Esta modalidade já existe'
    }
  }

  formatFindByIdResponse (response) {
    return {
      ...response
    }
  }

  formatData (data) {
    return {
      ...data
    }
  }
}

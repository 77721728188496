const shippingMargins = 'shipping-margins'

export const PATH_SHIPPING_MARGINS = shippingMargins

export const NAME_SHIPPING_MARGINS = shippingMargins

export default {
  path: PATH_SHIPPING_MARGINS,
  name: NAME_SHIPPING_MARGINS,
  component: () => import('@/views/ShippingMargins'),
  meta: {
    breadcrumb: [
      { text: 'Regras' },
      { text: 'Margem de frete' }
    ],
    title: 'Margem'
  }
}

import { OrderAnalysisDashboard } from '@/services/FreightOrderAnalysisApi/OrderAnalysisDashboard'
import { trimObject } from '@/utils/functions'

export default {
  async getApiDashboard ({ getters, commit }) {
    commit('SET_LOADING', true)
    let dashboardService = new OrderAnalysisDashboard()

    const filters = trimObject({
      ...getters.filters
    })

    dashboardService = await dashboardService.list(filters)
    if (dashboardService) {
      commit('SET_DASHBOARD', dashboardService?.data || {})
    }
    commit('SET_LOADING', false)
  }
}

const contracts = 'contracts'

export const PATH_CONTRACTS = contracts

export const NAME_CONTRACTS = contracts

export default {
  path: PATH_CONTRACTS,
  name: NAME_CONTRACTS,
  component: () => import('@/views/Contracts'),
  meta: {
    breadcrumb: [{ text: 'Cadastros' }, { text: 'Cadastro de tabela de frete' }],
    title: 'Cadastro de tabela de frete',
    returnButton: false,
    action: {
      text: 'Adicionar tabela',
      icon: 'add',
      route: { name: 'contract-create' }
    }
  }
}

import store from '@/store'

const shippingCompanies = 'carriers'

export const PATH_SHIPPING_COMPANIES = shippingCompanies

export const NAME_SHIPPING_COMPANIES = shippingCompanies

export default {
  path: PATH_SHIPPING_COMPANIES,
  name: NAME_SHIPPING_COMPANIES,
  component: () => import('@/views/ShippingCompanies'),
  meta: {
    breadcrumb: [{ text: 'Cadastros' }, { text: 'Cadastro de transportadoras' }],
    title: 'Cadastro de transportadoras',
    action: {
      text: 'Adicionar Transportadoras',
      icon: 'add',
      ifcEditor: {
        editor: 'ShippingCompanies',
        onSaved: () => store.dispatch('ShippingCompanies/getApiShippingCompanies')
      }
    }
  }
}

export default {
  SET_DASHBOARD (state, dashboard) {
    state.dashboard = dashboard
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_FILTERS (state, filters) {
    state.filters = filters
  }
}

<template>
  <ifc-input
    v-model="model"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    hide-details="auto"
    :optional="optional"
    :rules="rulesFormated"
    :hint="hint"
    v-mask="['+## (##) ####-####', '+## (##) #####-####']"
    :disabled="disabled || loading"
    :loading="loading"
    :error-messages="errors"
    @keypress="resetError()"
  ></ifc-input>
</template>
<script>
import { mask } from 'vue-the-mask'
export default {
  name: 'IfcEditorPhone',
  directives: { mask },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    },
    resetError () {
      if (this.errors?.length > 0) {
        this.$emit('resetError', this.field)
      }
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    }
  }
}
</script>

import { Quotations } from '@/services/FreightQuotationApi/Quotations'

export default {
  async getApiQuotations ({ getters, commit }) {
    commit('SET_LOADING', true)
    let quotations = new Quotations()
    quotations = await quotations.list({
      ...getters.currentConfigApi,
      ...getters.filterFormatted
    })
    if (quotations) {
      commit('SET_QUOTATIONS', quotations?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', quotations)
    }
    commit('SET_LOADING', false)
  },

  async getQuotationById ({ commit }, quotationId) {
    commit('SET_LOADING', true)

    let quotation = new Quotations()

    quotation = await quotation.findById(quotationId)

    if (quotation) {
      commit('SET_CURRENT_QUOTATION', quotation || null)
    }

    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', [])
      dispatch('getApiQuotations')
    }
  }
}

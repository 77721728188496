import {
  LOCALSTORAGE_CACHE_CONTRACTS_TABLE_CONFIGURATION,
  LOCALSTORAGE_CACHE_CONTRACTS_FILTERS
} from '@/constants/localStorage'

export default {
  SET_CONTRACTS (state, contracts) {
    state.contracts = contracts
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  TOUGGLE_SHOW_FILTERS (state) {
    state.showFilters = !state.showFilters
  },
  ADD_FILTER (state, { field, value }) {
    if (value) {
      const filters = {
        ...state.filters
      }
      filters[field] = value
      state.filters = filters
      localStorage.setItem(LOCALSTORAGE_CACHE_CONTRACTS_FILTERS, JSON.stringify(state.filters))
    }
  },
  SET_FILTERS (state, filters) {
    state.filters = filters
  },
  REMOVE_FILTER (state, field) {
    const filters = {
      ...state.filters
    }
    delete filters[field]
    state.filters = filters
    localStorage.setItem(LOCALSTORAGE_CACHE_CONTRACTS_FILTERS, JSON.stringify(state.filters))
  },
  SET_CURRENT_CONFIG (state, currentConfig) {
    state.currentConfig = currentConfig
    const currentConfigStringify = JSON.stringify(state.currentConfig)
    localStorage.setItem(LOCALSTORAGE_CACHE_CONTRACTS_TABLE_CONFIGURATION, currentConfigStringify)
  },
  SET_CURRENT_CONFIG_FROM_API (state, { currentPage, total, pages }) {
    state.currentConfig.page = currentPage
    state.total = total
    state.pageCount = pages
  },
  SET_PAGE (state, page) {
    state.currentConfig.page = page
  },
  SET_PER_PAGE (state, perPage) {
    state.currentConfig.perPage = perPage
  },
  SET_SORT_BY (state, sortBy) {
    state.currentConfig.sortBy = sortBy
  },
  SET_SORT_DESC (state, sortDesc) {
    state.currentConfig.sortDesc = sortDesc
  }
}

const KEY_LOCALSTORAGE = 'freight-admin:filters'

export default {
  namespaced: true,
  state: {
    filters: {}
  },
  actions: {
    updateFilters (context, data) {
      context.commit('SET_FILTER_PAGE', data)
    },
    getFilterByPage (context, page) {
      const nowTime = new Date().getTime()

      context.commit('GET_FILTER', page)

      const filter = context.state.filters[page]

      if (filter && nowTime < filter.expire) {
        return filter.data
      } else {
        delete context.state.filters[page]
        context.commit('UPDATE_FILTER')
        return []
      }
    }
  },
  mutations: {
    GET_FILTER (state, page) {
      if (Object.values(state.filters).length === 0) {
        const filters = JSON.parse(sessionStorage.getItem(KEY_LOCALSTORAGE))

        // if in sessionStorage 'freight-admin:filters' have any data, will be placed in state.filters
        if (filters && Object.values(filters).length > 0) {
          // set data from sessionStorage
          state.filters = filters
        }
      }
    },
    SET_FILTER_PAGE (state, { filter, page }) {
      const fiveMinutes = new Date().getTime() + (1000 * 60 * 5)
      // salesChannel received in parameter is seted to state
      state.filters[page] = {
        data: filter,
        expire: fiveMinutes
      }
      sessionStorage.setItem(KEY_LOCALSTORAGE, JSON.stringify(state.filters))
    },
    UPDATE_FILTER (state, filters) {
      if (filters) {
        state.filters = filters
      }
      sessionStorage.setItem(KEY_LOCALSTORAGE, JSON.stringify(state.filters))
    }
  }
}

const dashboardMonitoringDetail = 'monitoring/orders'

export const PATH_DASHBOARD_MONITORING_DETAIL = dashboardMonitoringDetail

export const NAME_DASHBOARD_MONITORING_DETAIL = dashboardMonitoringDetail

export default {
  path: PATH_DASHBOARD_MONITORING_DETAIL,
  name: NAME_DASHBOARD_MONITORING_DETAIL,
  component: () => import('@/views/DashboardMonitoringDetail'),
  meta: {
    breadcrumb: [
      { text: 'Gestão' },
      { text: 'Monitoramento' }
    ],
    title: 'Monitoramento de Pedidos',
    returnButton: true,
    back: {
      route: { name: 'monitoring' }
    }
  }
}

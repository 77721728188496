import { OrderExportCSVNotification } from '@/components/Notifications/Types/OrderExportCSVNotification'
import { UploadFreightsErrorNotification } from '@/components/Notifications/Types/UploadFreightsErrorNotification'
import { UploadFreightsSuccessNotification } from '@/components/Notifications/Types/UploadFreightsSuccessNotification'
import { StoreStocksImportFileNotification } from '@/components/Notifications/Types/StoreStocksImportFileNotification'
import { ServerErrorNotification } from '@/components/Notifications/Types/ServerErrorNotification'

export default {
  'orders.export.csv': OrderExportCSVNotification,
  'upload.freights.error': UploadFreightsErrorNotification,
  'upload.freights.success': UploadFreightsSuccessNotification,
  'server.error': ServerErrorNotification,
  STORE_STOCKS_IMPORT_FILE: StoreStocksImportFileNotification
}

import { FreightApi } from './FreightApi'

export class AccountsDeliveryMode extends FreightApi {
  constructor () {
    super()
    this.uri = '/accounts-delivery-mode'
  }

  serviceErrorsDictionary = {
    friendlyName: {
      'already.exist': 'Nome já existe'
    }
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.uri}/${id}`, { params })
      .then(response => (this.formatFindByIdResponse(response.data.data)))
      .catch(() => {
        return false
      })
  }

  create (data) {
    return this.instance.post(this.uri, this.formatData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id, data) {
    return this.instance.put(`${this.uri}/${id}`, this.formatData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  formatFindByIdResponse (response) {
    return {
      ...response,
      friendlyName: response?.deliveryMode?.friendlyName,
      deliveryMode: {
        ...response?.deliveryMode,
        delivery_mode: response?.deliveryMode?.name
      }
    }
  }

  formatData (data) {
    const accountIds = []
    data.accounts.forEach(account => {
      accountIds.push(account.id)
    })
    return {
      friendlyName: data.friendlyName,
      deliveryModeId: data.deliveryMode.id,
      accountIds
    }
  }
}

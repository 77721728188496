const DEFAULT_CURRENT_CONFIG = {
  sortBy: [
    'receiverName'
  ],
  sortDesc: [
    false
  ],
  page: 1,
  perPage: 50
}

export default {
  orders: [],
  orderDetail: {},
  statusCodeList: [],
  activeStep: -1,
  total: 0,
  filters: {
    orderCreatedAt: null,
    orderUpdatedAt: null
  },
  pageCount: 0,
  loading: false,
  showFilters: false,
  currentConfig: DEFAULT_CURRENT_CONFIG,
  exportLoading: false
}

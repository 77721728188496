<template>
  <ifc-autocomplete
    v-model="model"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :rules="rulesFormated"
    :hint="hint"
    :options="options"
    :disabled="disabled || loading"
    :loading="loading"
  ></ifc-autocomplete>
</template>
<script>
export default {
  name: 'IfcEditorAutocomplete',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue) {
        this.model = this.defaultValue
      }
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    value: {
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    }
  }
}
</script>

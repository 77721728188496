import { ShippingCompanies } from '@/services/FreightApi/ShippingCompanies'
import { trimObject } from '@/utils/functions'

export default {
  async getApiShippingCompanies ({ state, getters, commit }) {
    commit('SET_LOADING', true)
    let shippingCompanies = new ShippingCompanies()

    const filters = trimObject({
      ...getters.currentConfigApi,
      ...state.filters
    })

    shippingCompanies = await shippingCompanies.list(filters)
    if (shippingCompanies) {
      commit('SET_SHIPPING_COMPANIES', shippingCompanies?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', shippingCompanies)
    }
    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', [])
      dispatch('getApiShippingCompanies')
    }
  }
}

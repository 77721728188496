export const breadcrumbBase = {
  text: 'Página inicial',
  href: '/',
  to: '/?redirect=true'
}

const prepareHomepage = (route) => {
  let preparedRoutes = []

  if (!Array.isArray(route)) {
    preparedRoutes = [route]
  } else {
    preparedRoutes = route
  }

  preparedRoutes = preparedRoutes.map((route) => {
    if (route?.meta?.breadcrumb) {
      route.meta.breadcrumb.unshift(breadcrumbBase)
    }

    return route
  })

  if (preparedRoutes.length === 1) {
    return preparedRoutes[0]
  } else {
    return preparedRoutes
  }
}

export default prepareHomepage

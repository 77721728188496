import { FreightApi } from './FreightApi'

export class Contract extends FreightApi {
  constructor () {
    super()
    this.uri = '/contracts'
  }

  findById (id, params = {}) {
    return this.instance.get(`${this.uri}/${id}`, { params })
      .then(response => (this.formatFindByIdData(response.data.data)))
      .catch(() => {
        return false
      })
  }

  create (data) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return this.instance.post(this.uri, this.formatCreateData(data), headers)
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id, data) {
    return this.instance.patch(`${this.uri}/${id}`, this.formatUpdateData(data))
      .then(response => ({
        status: true,
        data: response?.data?.data
      }))
      .catch(error => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  formatFindByIdData (data) {
    return {
      ...data,
      carrier: {
        ...data.carrier,
        carrier: data.carrier.name
      },
      deliveryMode: {
        ...data.deliveryMode,
        delivery_mode: data.deliveryMode.name
      },
      selectedLocations: data.locations.map(location => ({
        ...location,
        salesChannels: location.accounts.reduce(
          (acc, curr) => curr.salesChannels ? ([...acc, ...curr.salesChannels]) : acc,
          []
        )
      })),
      files: this.formatFindByIdDataFiles(data.batches)
    }
  }

  formatFindByIdDataFiles (files) {
    return files.map((file, fileIndex) => ({
      ...file,
      name: file.originalName || file.fileName || 'Arquivo ' + (fileIndex + 1)
    }))
  }

  formatCreateData (data) {
    const formatedData = new FormData()
    formatedData.append('name', data.name)
    formatedData.append('active', data.active)
    formatedData.append('minimumPercent', data.minimumPercent)
    formatedData.append('carrier', data.carrier.id)
    formatedData.append('deliveryMode', data.deliveryMode.id)
    formatedData.append('targets', this.formatTargets(data.targets))
    formatedData.append('locations', JSON.stringify(this.formatLocations(data.selectedLocations)))
    if (data.taxCubeFactor) {
      formatedData.append('taxCubeFactor', data.taxCubeFactor)
    }
    if (data.exemptionCubeFactor) {
      formatedData.append('exemptionCubeFactor', data.exemptionCubeFactor)
    }
    if (data.minimumValueAcceptable || data.maximumValueAcceptable) {
      formatedData.append('minimumValueAcceptable', data.minimumValueAcceptable || 0)
    }
    if (data.maximumValueAcceptable || data.minimumValueAcceptable) {
      formatedData.append('maximumValueAcceptable', data.maximumValueAcceptable || 0)
    }
    if (data.deliveryMode.scheduled && data.deliveryScheduleSettings) {
      formatedData.append('deliveryScheduleSettings', JSON.stringify(this.formatDeliveryScheduleSettings(data)))
    }
    data.files.map((file, fileKey) => {
      formatedData.append('files', file)
      return file
    })
    const object = {}
    formatedData.forEach(function (value, key) {
      object[key] = value
    })
    return formatedData
  }

  formatLocations (locations) {
    return locations.map(location => ({
      id: location.id,
      accounts: location.accounts.map(store => ({
        id: store.id,
        salesChannels: location.salesChannels.map(({ id }) => id)
      }))
    }))
  }

  formatTargets (targets = []) {
    return JSON.stringify(targets.map(target => target.code))
  }

  formatDeliveryScheduleSettings ({ deliveryScheduleSettings, minTime, maxTime }) {
    return {
      deliveryScheduleEnabled: true,
      maxRangeToShowDelivery: minTime,
      maxRangeDelivery: maxTime,
      dayOfWeekForDelivery: this.getDayOfWeekForDelivery(deliveryScheduleSettings)
    }
  }

  getDayOfWeekForDelivery (deliveryScheduleSettings) {
    const dayOfWeekForDelivery = []
    deliveryScheduleSettings.forEach(week => {
      week.week.map(day => ({
        dayOfWeek: day.id,
        periods: [
          {
            startTime: week.startTime,
            endTime: week.endTime
          }
        ]
      })).forEach(item => {
        dayOfWeekForDelivery.push(item)
      })
    })
    return dayOfWeekForDelivery
  }

  formatUpdateData (data) {
    const selectedLocations = this.formatLocations(data.selectedLocations)
    const alreadyAdded = selectedLocations.find((loc) => loc.id === data?.location?.id) !== undefined
    const locations = (() => {
      if (!alreadyAdded) {
        return [...selectedLocations]
      }
      return selectedLocations
    })()

    const resultFormat = {
      active: data.active,
      minimumPercent: data.minimumPercent,
      locations
    }

    return resultFormat
  }
}

<template>
  <div class="ifc-editor-file">
    <ifc-upload
      v-model="model"
      @onUpload="onUpload"
      :accept="accept"
      :title="title"
      :disabled="disabled"
      :description="description"
    >
      <template slot="drag">
        <p style="margin-bottom: 0;">
          <!-- <ifc-upload-icon
            color="primary"
            icon="file_upload"
          ></ifc-upload-icon> -->
          <img :src="$FileProxy('static/icons/upload-icon.PNG')" width="30" alt=""/>
        </p>
        <h3>Upload da Tabela</h3>
        <h4>Arraste arquivos XLSX ou faça o upload clicando aqui</h4>
      </template>
    </ifc-upload>
    <v-dialog v-model="dialog" width="278">
      <v-card>
        <v-app-bar flat dense color="white">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text class="message">
          <div class="message__icon">
            <img :src="$FileProxy('static/icons/error-icon.png')" width="70" alt=""/>
          </div>
          <div class="message__text">
            <h4>{{invalidMessage}}</h4>
            <div class="message__files">
            <small v-if="invalidDescription !== ''"><b>{{invalidDescription}}</b></small>
              <p v-for="(invalidFile, invalidFileKey) in invalidFiles" :key="invalidFileKey">
                <v-divider v-if="invalidFileKey"></v-divider>
                {{ invalidFile.name }} - {{ formatBytes(invalidFile.size) }}
              </p>
            </div>
          </div>
          <v-btn color="#7676FF" block large class="white--text" @click="dialog = false" depressed>
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'IfcEditorFile',
  data: () => ({
    dialog: false,
    invalidMessage: '',
    invalidDescription: '',
    invalidFiles: []
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rulesFormated () {
      const rules = this.rules
      if (this.required) {
        rules.unshift(
          v => !!v || 'Campo obrigatório!'
        )
      }
      return rules
    }
  },
  methods: {
    onUpload: function (files) {
      if (this.checkFilesSize(files)) {
        this.model = files
      }
      files = this.formatFiles(files)
      this.model = files.filter(file => file.extension === 'xlsx')
      const fileNotXlsx = files.filter(file => file.extension !== 'xlsx')
      if (fileNotXlsx.length > 0) {
        this.invalidFiles = [...fileNotXlsx]
        this.invalidMessage = 'Sistema aceita apenas arquivos xlsx'
        this.invalidDescription = 'Abaixo segue arquivos não aceitos:'
        this.dialog = true
      }
    },
    formatFiles (files) {
      return files.map(file => {
        file.extension = file.name.split('.').pop()
        return file
      })
    },
    checkFilesSize: function (files) {
      this.invalidFiles = files.filter(file => (file.size > 52428800)) // 50MB
      if (this.invalidFiles.length > 0) {
        this.invalidDescription = ''
        this.invalidMessage = `Limite máximo de ${this.formatBytes(this.maxSize)}`
        this.dialog = true
        return false
      }
      return true
    },
    formatBytes: function (bytes, decimals = 0) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  },
  props: {
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 52428800 // 50MB
    }
  }
}
</script>
<style scoped>
  .ifc-editor-button {
    padding-top: 25px;
  }
  .ifc-editor-file {
    border: unset !important;
    padding: unset !important;
    margin: unset !important;
  }
  .message {
    padding: 0 38px 39px 38px !important;
  }
  .message__icon {
    text-align: center;
  }
  .message__text {
    text-align: center;
    font-size: 16px;
    padding: 30px 0;
  }
  .message__files {
    padding-top: 20px;
    font-size: 12px;
  }
  .message__files p {
    margin-bottom: 5px;
  }
  .ifc-upload >>> .v-card__actions {
    padding: 8px 0px !important;
  }
</style>

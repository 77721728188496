<template>
  <div class="ifc-editor-status">
    <ifc-status-toggle v-model="model" v-bind="$props"></ifc-status-toggle>
  </div>
</template>
<script>
export default {
  name: 'IfcEditorStatus',
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setDefaultValue () {
      if (this.defaultValue !== undefined) {
        this.model = this.defaultValue
      }
    }
  },
  mounted: function () {
    this.setDefaultValue()
  },
  props: {
    persistentHint: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    value: {
      required: true
    },
    mask: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    required: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      default: null
    }
  }
}
</script>
<style scoped>
  .ifc-editor-status {
    padding-top: 10px;
  }
</style>

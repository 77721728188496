<template>
  <div class="ifc-editor-title" :style="{background: background, color: color}">
    <span>{{ label }}</span>
  </div>
</template>
<script>
export default {
  name: 'IfcEditorTitle',
  props: {
    label: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'white'
    },
    color: {
      type: String,
      default: '#414f62'
    }
  }
}
</script>
<style scoped>
  .ifc-editor-title {
    padding: 0px 0;
    width: 100% !important;
  }
</style>

import { ShippingCompaniesEditor } from '@/views/ShippingCompanies/ShippingCompaniesEditor'
import { AccountsDeliveryModeEditor } from '@/views/AccountsDeliveryModes/AccountsDeliveryModeEditor'
import { DeliveryModeEditor } from '@/views/DeliveryModes/DeliveryModeEditor'
import { ShippingMarginsEditor } from '@/views/ShippingMargins/ShippingMarginsEditor'

export default {
  ShippingCompanies: ShippingCompaniesEditor,
  AccountsDeliveryMode: AccountsDeliveryModeEditor,
  DeliveryModes: DeliveryModeEditor,
  ShippingMargins: ShippingMarginsEditor
}

const dashboardMonitoring = 'monitoring'

export const PATH_DASHBOARD_MONITORING = dashboardMonitoring

export const NAME_DASHBOARD_MONITORING = dashboardMonitoring

export default {
  path: PATH_DASHBOARD_MONITORING,
  name: NAME_DASHBOARD_MONITORING,
  component: () => import('@/views/DashboardMonitoring'),
  meta: {
    breadcrumb: [
      { text: 'Gestão' },
      { text: 'Monitoramento' }
    ],
    title: 'Monitoramento',
    returnButton: false
  }
}

export default {
  'freight/contract-not-found': 'Transportadora não encontrada para a simulação',
  'freight/freight-not-found': 'Frete não encontrado',
  'freight/cubic-dimensions-not-found': 'Não foi possível encontrar frete para esse trecho/volumes',
  'freight/zipcode-not-found': 'Frete não encontrado para o trecho selecionado',
  'freight/invalid-tenant': 'Tenant é obrigatório',
  'freight/invalid-location': 'Origem e Destino obrigatório',
  'freight/height-must-be-a-number': 'Altura obrigatória',
  'freight/width-must-be-a-number': 'Largura obrigatória',
  'freight/length-must-be-a-number': 'Comprimento obrigatório',
  'freight/weight-must-be-a-number': 'Peso obrigatório',
  'freight/unitary-value-must-be-a-number': 'Valor obrigatório',
  'freight/quantity-must-be-a-number': 'Quantidade obrigatória',
  'freight/origin-zipcode-must-be-a-number-string': 'Origem inválida',
  'freight/origin-zipcode-must-be-greater-than-7-characters': 'Origem inválida',
  'freight/destination-zipcode-must-be-a-number-string': 'Destino inválido',
  'freight/destination-zipcode-must-be-greater-than-7-characters': 'Destino inválido',
  'freight/destination-zipcode-must-be-shorter-than-12-characters': 'Destino inválido'
}

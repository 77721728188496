const DEFAULT_CURRENT_CONFIG = {
  sortBy: [
    'name'
  ],
  sortDesc: [
    false
  ],
  page: 1,
  perPage: 10
}

export default {
  contracts: [],
  total: 0,
  filters: {},
  pageCount: 0,
  loading: false,
  showFilters: false,
  currentConfig: DEFAULT_CURRENT_CONFIG
}

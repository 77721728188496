<template>
  <div class="ifc-editor-button">
    <ifc-button
      @click="action()"
      v-text="label"
      v-bind="$props"
      block
      large
    ></ifc-button>
    <v-row>
      <v-col class="pt-4" v-if="error">
        <span class="ifc-error-message ifc-color--error-300">{{errorMessage}}</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'IfcEditorButton',
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    action: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
  .ifc-editor-button {
    padding-top: 20px;
  }

  .ifc-error-message {
    font-size: .75rem;
    line-height: 1.125rem;
  }
</style>

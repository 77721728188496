import Vue from 'vue'
import VueRouter from 'vue-router'

import beforeEach from '@/router/guard/beforeEach'
import routes from '@/router/routes'
import { NAME_CONTRACTS } from '@/router/routes/contracts'

Vue.use(VueRouter)

export const createRouter = (base = '') => {
  const router = new VueRouter({
    mode: 'hash',
    base,
    routes: [
      ...routes,
      {
        path: '/',
        redirect: {
          name: NAME_CONTRACTS
        }
      }
    ]
  })

  beforeEach(router)

  return router
}

export default createRouter()

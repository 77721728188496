export default {
  currentConfigApi: ({ currentConfig }) => {
    let orderDirection = null
    let orderBy = currentConfig.sortBy[0]
    if (typeof currentConfig.sortDesc[0] === 'boolean') {
      orderDirection = currentConfig.sortDesc[0] ? 'desc' : 'asc'
    }
    if (orderBy === 'originZipCode' || orderBy === 'destinationZipCode') {
      orderBy = `request.data.freight.${orderBy}`
    }
    return {
      perPage: currentConfig.perPage || null,
      page: currentConfig.page && currentConfig.page > 1 ? currentConfig.page : null,
      orderBy: orderBy,
      orderDirection: orderDirection
    }
  },
  getCurrentQuotation: (state) => {
    if (state.currentQuotation) return state.currentQuotation

    return null
  },
  filterFormatted: (state) => {
    const filters = { ...state.filters }
    for (const key in state.filters) {
      if ((key === 'originZipCode' || key === 'destinationZipCode') && state.filters[key]) {
        filters[key] = state.filters[key].replaceAll('-', '')
      }
    }
    return filters
  },
  filtersCount: (state) => {
    return Object.keys(state.filters)
      .filter(key => (state.filters[key]))
      .length
  }
}
